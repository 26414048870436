import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import {
    CalculatorOutlined, CloseOutlined,
    CopyOutlined,
    InsertRowBelowOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { addDoc, setDoc } from '@firebase/firestore';
import { Core } from '@pdftron/webviewer';
import { Button, Divider, Flex, FloatButton, Popover, Tooltip, Typography } from 'antd';
import { message } from 'antd/lib';
import Decimal from 'decimal.js';
import { doc, getDoc } from 'firebase/firestore';
import numeral from 'numeral'
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { CustomDataKey } from '@/constants/pdfViewer/customDataKey.ts';
import { reportReviewRef } from '@/firestore/api/reportReview.ts';
import {
    ReviewIdentifiedBlock,
    ReviewIdentifiedBlockConfidence, reviewIdentifiedBlockRef, ReviewIdentifiedBlockRelatedChild,
} from '@/firestore/api/reviewIdentifiedBlock.ts';
import { ReviewStepComment, reviewStepCommentRef } from '@/firestore/api/reviewStepComment.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { identifiedBlockColorByConfidence } from '@/pages/ReviewPage';
import { randomString } from '@/utils/randomString.ts';
import { CustomToolNames } from '@/widgets/PdfViewer2';
import { SUM_SELECT_MODE_QUERY_CONFIG } from '@/widgets/SumSelect/SumSelect.contants.ts';
import { normalizeTextWithNumbers } from '@/widgets/SumSelect/SumSelect.utils.ts';

import { SumSelectProps, SumSelectMode } from './SumSelect.types'
import { PdfViewerContext2 } from '../../App.tsx';
import { SUM_SELECT_QUERY_PARAM } from '../MagicButtons/MagicButtons.constants.ts';

/**
 * Formats should be supported:
 * 8,1
 * 8.001,1
 * 888.888
 * 84.001,1
 * 88.888.001,1
 * 8.1
 * 8,001.1
 * 888,888
 * 84,001.1
 * 88,888,001.1
 * (8,001.1)
 * (8.001,1)
 * (8,001.12)
 * 8,001.12
 * 8.001,12
 * 8 001,12
 * 8 001.12
 * -8 001,12
 * -8,001.12
 * 12:18
 * 88 888 001.12
 * 88 888 001,12
 */
export const SumSelect = (props: SumSelectProps) => {

    const { id: reportId } = useParams()

    const { pdfInstance } = useContext(PdfViewerContext2)
    
    const [textForSum, setTextForSum] = useState<string>('')
    const [totalComponents, setTotalComponents] = useState<number[]>([])
    const [total, setTotal] = useState<Decimal>(() => new Decimal(0))
    const [sumSelectMode, setSumSelectMode] = useQueryParam<SumSelectMode>(SUM_SELECT_MODE_QUERY_CONFIG.name, SUM_SELECT_MODE_QUERY_CONFIG.type)
    const [open, setOpen] = useQueryParam(SUM_SELECT_QUERY_PARAM.name, SUM_SELECT_QUERY_PARAM.type)
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const [decimals, setDecimals] = useState<number>(2)
    const [componentAnnotations, setComponentAnnotations] = useState<Core.Annotations.Annotation[]>([])
    const [totalAnnotation, setTotalAnnotation] = useState<Core.Annotations.Annotation>()
    const lastAnnotation = useRef<Core.Annotations.Annotation>(null)
    const { pdfDocument, documentViewer } = useViewerDocument()
    const [textUpdater, setTextUpdater] = useState(0)

    const { annotationManager } = useViewerDocument()
    
    const authData = useContext<AuthData>(AuthDataContext)
    
    const lastValidationRes = useRef<ReviewIdentifiedBlockConfidence>('neutral')

    const formatNum = (num: number) => numeral(num).format(`0,0.${'0'.repeat(decimals)}`)

    const handleAnnotationAdd = useCallback(async (annotaion: Core.Annotations.Annotation) => {
        const docViewer = pdfInstance.docViewer as Core.DocumentViewer;
        const annotationRect = annotaion.getRect()

        // To availd accidentallu included valued into the list
        const coordsCorrectionVal = 3

        annotationRect.x1 += coordsCorrectionVal
        annotationRect.y1 += coordsCorrectionVal
        annotationRect.x2 -= coordsCorrectionVal
        annotationRect.y2 -= coordsCorrectionVal

        const page = annotaion.getPageNumber()
        const text = await docViewer.getDocument().getTextByPageAndRect(page, annotationRect)

        console.log('Selected:', text)

        setTextForSum(text)
        setTextUpdater(prev => prev + 1)
        lastAnnotation.current = annotaion
    }, [pdfInstance, sumSelectMode])

    useEffect(() => {
        if(pdfInstance ) {
            const callback = (annotations: Core.Annotations.Annotation[], action, { imported }) => {
                // Ignore auto added itemss
                if (imported) {
                    return;
                }

                const annotation = annotations[0];

                if (action === 'add' && annotation?.ToolName === CustomToolNames.SumSelect) {
                    handleAnnotationAdd(annotation)
                }
            }
                
            const docViewer = pdfInstance.docViewer as Core.DocumentViewer;
            const annotManager = docViewer.getAnnotationManager();

            if(open) {
                annotManager.addEventListener('annotationChanged', callback);
            } else {
                annotManager.removeEventListener('annotationChanged', callback);

                // Delete created annotations
                const toDelete = [...componentAnnotations, totalAnnotation].filter(Boolean)
                annotManager.deleteAnnotations(toDelete)
                lastValidationRes.current = 'neutral'
                setTotal(new Decimal(0))
                setTotalComponents([])
            }
        }
    }, [pdfInstance, open]);

    useEffect(() => {
        setSumSelectMode(SumSelectMode.NEW)
        setOpen(false)
    }, []);
    
    const handleClick = () => {
        setOpen(!open)
    }

    const totalVal = Number(total.toDecimalPlaces(2).toString())

    useEffect(() => {
        if( !documentViewer) return
        
        const annotManager = documentViewer.getAnnotationManager();
        const annotaion = lastAnnotation.current

        if(!textForSum?.length) {
            if(annotaion) {
                message.warning('No numbers found')
                annotManager.deleteAnnotations([annotaion])
            }

            return
        }

        if(sumSelectMode === SumSelectMode.TOTAL) {
            setTotalAnnotation(annotaion)
        } else if(sumSelectMode === SumSelectMode.ADD) {
            setComponentAnnotations(prev => [...prev, annotaion])
        } else {
            if(sumSelectMode === SumSelectMode.NEW) {
                const annotations = [totalAnnotation, ...componentAnnotations].filter(Boolean)
                if(annotations.length) {
                    annotManager.deleteAnnotations(annotations)
                    lastValidationRes.current = 'neutral'
                }
            }
            
            setComponentAnnotations([annotaion])
            setTotalAnnotation(undefined)
        }

        const { numbers, maxDecimals } = normalizeTextWithNumbers(textForSum)
      
        setDecimals(maxDecimals)

        if(sumSelectMode === SumSelectMode.NEW) {
            setTotalComponents(numbers)
            setTotal(numbers.reduce((acc, el) => acc.plus(el), new Decimal(0)))
        } else if(sumSelectMode === SumSelectMode.ADD) {
            setTotalComponents([...totalComponents, ...numbers])
            setTotal(numbers.reduce((acc, el) => acc.plus(el), total))
        } else if(sumSelectMode === SumSelectMode.TOTAL) {
            if(numbers.length === 0) {
                message.warning('No numbers found')
                annotManager.deleteAnnotations([annotaion])
            } else if (numbers.length > 1 ) {
                message.warning('Select only one number for total validation')
                annotManager.deleteAnnotations([annotaion])
            } else {
                if(totalVal !== numbers[0]) {
                    message.error(`Total is not equal to the sum of components: ${numbers[0]}`)
                    lastValidationRes.current = 'invalid'
                } else {
                    message.success('Total is equal to the sum of components')
                    lastValidationRes.current = 'valid'
                }
                
                (async () => {
                    const document = doc(reportReviewRef, reportId)
                    const reportSnap = await getDoc(document)
                    const reportData = reportSnap.data()

                    const rect = annotaion.getRect()

                    const children: ReviewIdentifiedBlockRelatedChild[] = componentAnnotations.map(el => {
                        const rect = el.getRect()

                        return ({
                            coordinates: [rect.x1, rect.y1, rect.getWidth(), rect.getHeight()],
                            blockType: 'relatedChild',
                            // FIXME: Add this later. Content should be normalized
                            content: '',
                            pageIndex: el.getPageNumber() - 1,
                        })
                    })

                    if(reportData) {

                        // Create offline because need to set id first
                        const newDoc = doc(reviewIdentifiedBlockRef)

                        annotaion.setCustomData(CustomDataKey.relatedSnapshotId, newDoc.id)
                        annotaion.setCustomData(CustomDataKey.relatedStep, reportData.currentStep)

                        const color = identifiedBlockColorByConfidence(pdfInstance)[lastValidationRes.current]
                        annotaion.FillColor = color
                        annotaion.StrokeColor = color
                        // annotaion.Author = 'Sum Select'

                        annotManager.redrawAnnotation(annotaion)

                        setTimeout(() => {
                            // HACK: need to wait untill annotation created
                            annotationManager.trigger('annotationChanged', [[annotaion], 'modify', { imported: false, force: true }]);
                        }, 2000)

                        const matchLabel = randomString()
                        
                        const expectedTotal = formatNum(totalVal)
                        const actualTotal = formatNum(numbers[0])
                            
                        const data: ReviewIdentifiedBlock = {
                            autoConfidence: lastValidationRes.current,
                            content: formatNum(numbers[0]),
                            sumSelectMeta: {
                                expectedTotal: formatNum(totalVal),
                                actualTotal: formatNum(numbers[0]),
                            },
                            blockType: 'totalValidation',
                            stepKey: reportData?.currentStep,
                            companyId: authData.company.id,
                            entityId: reportData?.entityId,
                            coordinates: [rect.x1, rect.y1, rect.getWidth(), rect.getHeight()],
                            reportId,
                            // In this context annotation is related to total
                            pageIndex: annotaion.getPageNumber() - 1,
                            rejected: false,
                            relatedBlocks: children,
                            label: matchLabel,
                            annotaionId: annotaion.Id,
                        }
                        await setDoc(newDoc, data)

                        // Generate invalid message
                        if(lastValidationRes.current === 'invalid') {
                            const errorMessage = `There’s a discrepancy in the total. The total for the selection is <b>${expectedTotal}</b>, while the total in the table shows <b>${actualTotal}</b>.`
                        
                            const messageItem: ReviewStepComment = {
                                step: reportData.currentStep,
                                label: matchLabel,
                                errorType: 'total Validation',
                                suggestedMessage: errorMessage,
                                explanation: errorMessage,
                                pageNumber: annotaion.getPageNumber() - 1,
                                reportOnReviewId: reportSnap.id,
                                companyId: reportData.companyId,
                            }

                            await addDoc(reviewStepCommentRef, messageItem)
                        }
                        
                        setTotalAnnotation(undefined)

                        // setTotal(new Decimal(0))
                        // setTotalComponents([])

                        // Delete until we don't show them in UI
                        annotationManager.deleteAnnotations(componentAnnotations)
                    } else {

                        console.error('No report data found')
                    }
                })()

                setSumSelectMode(SumSelectMode.NEW)
            }
        }

        // Scroll to the bottom
        if(scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight
        }
        // textUpdater triggers the useEffect when text is empty
    }, [textForSum, documentViewer, textUpdater]);

    const totalComponentsEl = (
        <>
            <Flex style={{ maxHeight: 300, overflowX: 'auto', justifyContent: 'center' }}>
                <Flex
                    justify='center' align='center' vertical
                    style={{ height: 'fit-content', alignItems: 'flex-end' }}
                    ref={scrollContainerRef}
                >
                    {
                        totalComponents.map((el, index) => (
                            <Flex
                                key={index}
                                align='center'
                                justify='flex-end'
                            >
                                <Typography.Text>{
                                    formatNum(el)
                                }</Typography.Text>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
            <Flex style={{ borderTop: 'solid 1px rgba(16, 24, 40, 0.88)' }}>
                <Typography.Title level={5} style={{ marginTop: 8 }}>{
                    formatNum(totalVal)
                }</Typography.Title>
            </Flex>
        </>
    )

    const popoverContent = (
        <>
            <Divider style={{ margin: '12px 0px' }}/>
            <Flex
                justify='center' align='center' vertical
            >
                {totalVal !== 0 ? <Typography.Text>{totalComponentsEl}</Typography.Text> : <Typography.Text type='secondary'>No numbers found</Typography.Text>}
            </Flex>
            <Divider style={{ margin: '12px 0px' }}/>
            <Flex align='flex-end' justify='space-between'>
                <Flex gap={8}>
                    <Tooltip title='Add more numbers to the calculations'>
                        <Button
                            type='text'
                            icon={<PlusCircleOutlined style={{ color: sumSelectMode === SumSelectMode.ADD ? '#0253ff' : undefined }}/>}
                            onClick={() => {
                                sumSelectMode != SumSelectMode.ADD ? setSumSelectMode(SumSelectMode.ADD) : setSumSelectMode(SumSelectMode.NEW)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title='Include a total for comparison'>
                        <Button
                            disabled={totalComponents.length === 0}
                            type='text' icon={(
                                <InsertRowBelowOutlined
                                    style={{ color: sumSelectMode === SumSelectMode.TOTAL ? '#0253ff' : undefined }}
                                />
                            )}
                            onClick={() => {
                                sumSelectMode != SumSelectMode.TOTAL ? setSumSelectMode(SumSelectMode.TOTAL) : setSumSelectMode(SumSelectMode.NEW)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title='Reset values'>
                        <Button
                            type='text'
                            icon={<CloseOutlined/>}
                            onClick={() => {
                                const annotations = [totalAnnotation, ...componentAnnotations].filter(Boolean)
                                annotationManager?.deleteAnnotations(annotations)

                                setTotalAnnotation(undefined)
                                setComponentAnnotations([])
                                
                                setTotal(new Decimal(0))
                                setTotalComponents([])

                                setSumSelectMode(SumSelectMode.NEW)
                                lastAnnotation.current = null
                                lastValidationRes.current = 'neutral'
                            }}
                        />
                    </Tooltip>
                </Flex>
                <Flex gap={8}>
                    <Button
                        disabled={totalComponents.length === 0}
                        type='text' icon={<CopyOutlined/>} onClick={() => {
                            navigator.clipboard.writeText(formatNum(totalVal))
                            message.success('Copied to clipboard')
                        }}
                    />
                    {/*<Button type='text' disabled={totalComponents.length === 0} icon={<CheckOutlined/>}/>*/}
                </Flex>
            </Flex>
        </>
    )
    
    return (
        <Popover
            placement='rightTop'
            title={<Flex justify='center'>Sum Select ({totalComponents.length})</Flex>}
            content={popoverContent}
            trigger='click'
            open={open}
            overlayStyle={{ minWidth: '100px', maxHeight: 400 }}
            onOpenChange={setOpen}
            overlayInnerStyle={{ padding: 4 }}
        >
            <FloatButton
                type={open ? 'primary' : 'default'}
                icon={<CalculatorOutlined/>}
                onClick={handleClick}
                tooltip='Sum Select'
            />
        </Popover>
    )
}
