import React, { useContext, useEffect, useRef, useState } from 'react'

import { CheckOutlined, CloseOutlined, GatewayOutlined } from '@ant-design/icons';
import { addDoc } from '@firebase/firestore';
import { Button, CollapseProps, Flex, Result, Typography } from 'antd';
import { Collapse } from 'antd/lib';
import { and, doc, updateDoc, where } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import { AuthData, AuthDataContext } from '@/components/containers/AuthContext';
import { COLORS } from '@/constants/colors.ts';
import { ReportExtractedValues, reportExtractedValuesRef } from '@/firestore/api/reportExtractedValues.ts';
import {
    ValueValidation,
    ValueValidationConfidence,
    valueValidationRef,
    ValueValidationType,
} from '@/firestore/api/valueValidation.ts';
import { useViewerDocument } from '@/hooks/useViewerDocument.ts';
import { FOCUSED_ANNOTAION_SNAP_ID_QUERY_PARAM } from '@/pages/ReviewPage';

import { AnnotationContextProps } from './AnnotationContext.types'

export const AnnotationContext = (props: AnnotationContextProps) => {
    const [focusedSnapIdParam] = useQueryParam(FOCUSED_ANNOTAION_SNAP_ID_QUERY_PARAM.name, FOCUSED_ANNOTAION_SNAP_ID_QUERY_PARAM.type)
    const { annotationManager } = useViewerDocument()

    const authData = useContext<AuthData>(AuthDataContext)
    const { id: docId } = useParams()

    const lastSelectedAnnotation = useRef(focusedSnapIdParam)

    const [value, setValue] = useState()
    
    const [extractedValuesSnap, extractedValueLoading] = useDocument<ReportExtractedValues>(focusedSnapIdParam ? doc(reportExtractedValuesRef, focusedSnapIdParam) : undefined)

    const [valueValidationSnap, valueValidationLoading] = useCollection(valueValidationRef, and(
        where('companyId', '==', 'companyId'),
        where('reportId', '==', 'reportId'),
        where('extractedValueId', '==', focusedSnapIdParam),
    ))

    useEffect(() => {
        lastSelectedAnnotation.current = focusedSnapIdParam
    }, [focusedSnapIdParam]);

    const annotationId = focusedSnapIdParam ?? lastSelectedAnnotation.current

    useEffect(() => {
        if (!annotationManager || !annotationId || extractedValueLoading) return

        const dataStap = extractedValuesSnap
        const data = dataStap?.data()
        
        if (!data) return;

        const normValue = data.originalValue

        setValue(normValue)
    }, [annotationManager, annotationId, extractedValuesSnap, extractedValueLoading]);

    const text = 'lll'

    if (valueValidationLoading) return null

    const valueValidationDoc = valueValidationSnap?.docs[0]

    console.log('valueValidationSnap', valueValidationDoc?.id, valueValidationDoc?.data())

    const setAutoValidation = (type: ValueValidationType) => async (conf: ValueValidationConfidence) => {
        if (!valueValidationDoc) {
            const newItemData: ValueValidation = {
                companyId: authData.company.id,
                reportId: docId as string,
                extractedValueId: annotationId as string,
                manual: {
                    [type]: conf,
                },
            }
            debugger

            await addDoc(valueValidationRef, newItemData)
        } else {
            if (!valueValidationDoc) {
                debugger
                console.error('Doc not found for auto validation for annotation ', annotationId)
                return
            }
            debugger

            await updateDoc(valueValidationDoc.ref, {
                manual: {
                    ...valueValidationDoc.data().manual,
                    [type]: conf,
                },
            })

            debugger
        }
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: (
                <Flex gap={16}>
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        MA
                    </Typography.Title>
                    <Flex style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                        {/*<Radio.Group value={valueValidationDoc?.data()?.manual?.ma}>*/}
                        {/*    <ConfigProvider*/}
                        {/*        theme={{*/}
                        {/*            token: {*/}
                        {/*                colorPrimary: valueValidationDoc?.data()?.manual?.ma === 'valid' ? 'green' : 'red',*/}
                        {/*            },*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <Radio.Button*/}
                        {/*            value='valid'*/}
                        {/*            onClick={() => setAutoValidation('ma')('valid')}*/}
                        {/*            type={valueValidationDoc?.data()?.manual?.ma === 'valid' ? 'primary' : 'default'}*/}
                        {/*            icon={<PlusOutlined/>}*/}
                        {/*        />*/}
                        {/*    </ConfigProvider>*/}
                        {/*    <Radio.Button*/}
                        {/*        value='invalid' onClick={() => setAutoValidation('ma')('invalid')}*/}
                        {/*        type={valueValidationDoc?.data()?.manual?.ma === 'invalid' ? 'primary' : 'default'}*/}
                        {/*    >Invalid</Radio.Button>*/}
                        {/*</Radio.?Group>*/}
                        {/*<Button color='danger' type={valueValidationDoc?.data()?.manual?.ma === 'valid' ? 'primary' : 'default'} onClick={() => setAutoValidation('ma')('valid')}>Valid</Button>*/}
                        {/*<Button type={valueValidationDoc?.data()?.manual?.ma === 'invalid' ? 'primary' : 'default'} onClick={() => setAutoValidation('ma')('invalid')}>Invalid</Button>*/}

                        <Flex gap={16}>
                            <Flex gap={4}>
                                <Flex
                                    style={{ 
                                        height: 32, width: 32, borderRadius: '20%', background: '#52c41a', flexShrink: 0,
                                        opacity: valueValidationDoc?.data()?.manual?.ma === 'valid' ? 1 : 0.3,
                                        display: 'flex',
                                    }}
                                    align='center'
                                    justify='center'
                                    onClick={() => setAutoValidation('ma')('valid')}
                                >
                                    {
                                        valueValidationDoc?.data()?.manual?.ma === 'valid' && <CheckOutlined style={{ color: 'white' }}/>
                                    }
                                </Flex>
                                <Flex
                                    align='center'
                                    justify='center'
                                    onClick={() => setAutoValidation('ma')('invalid')}
                                    style={{ height: 32, width: 32, borderRadius: '20%', background: '#ff4d4f', flexShrink: 0,
                                        opacity: valueValidationDoc?.data()?.manual?.ma === 'invalid' ? 1 : 0.3,
                                        display: 'flex',
                                    }}
                                >
                                    {
                                        valueValidationDoc?.data()?.manual?.ma === 'invalid' && <CheckOutlined style={{ color: 'white' }}/>
                                    }
                                </Flex>
                            </Flex>

                            <Button
                            // type={valueValidationDoc?.data()?.manual?.ma === 'notApplicable' ? 'primary' : 'default'}
                                onClick={() => setAutoValidation('ma')('notApplicable')} icon={<CloseOutlined/>}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            ),
            children: <p>{text}</p>,
        },
        {
            key: '2',
            label: 'This is panel header 2',
            children: <p>{text}</p>,
        },
        {
            key: '3',
            label: 'This is panel header 3',
            children: <p>{text}</p>,
        },
    ];

    return (
        <Flex
            style={{
                flexGrow: 1,
                alignItems: !value ? 'center' : 'flex-start',
                justifyContent: !value ? 'center' : 'flex-start',
                padding: 16,
            }}
        >
            {value ? (
                <Flex vertical style={{ width: '100%' }}>
                    <span style={{ textAlign: 'center' }}>
                        <Typography.Title
                            style={{
                                display: 'inline',
                                borderBottom: `solid 3px ${COLORS.main}`,
                                height: 'fit-content',
                            }}
                        >
                            {value}
                        </Typography.Title>
                    </span>
                    <Flex vertical style={{ width: '100%', marginTop: 24 }}>
                        <Collapse accordion items={items}/>
                    </Flex>
                </Flex>
            ) : (
                <Result
                    icon={<GatewayOutlined/>}
                    title='Please select a value'
                    extra={<Button>Create a New</Button>}
                />
            )}
        </Flex>
    )
}
